<script>
import SimpleTable from "./simple-table.vue";
import TransitionToggle from "../transitions/toggle.vue";
import BillableGeneralForm from "../forms/billable-general-form.vue";
import {mapGetters} from "vuex";

export default {
    name: "billables-manager",

    components: {BillableGeneralForm, TransitionToggle, SimpleTable},

    emits: ['update:modelValue', 'update:amount'],

    props: {
        modelValue: {
            type: Array,
            default: () => { return []; }
        },

        store: {
            type: String,
            default: 'billables'
        }
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        model: function () {
            return this.$store.getters[`${this.store}/list`];
        },

        summary: function () {
            return this.$store.getters[`${this.store}/summary`];
        },

        formattedSummary: function () {
            return this.summary ? this.formatter.format(this.summary) : null;
        },

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('invoices::items.relationships.billable').ucFirst(),
                    formatter: (value, index, item) => item?.billable?.name,
                },
                {
                    key: "quantity",
                    label: this.$t('invoices::items.columns.quantity').ucFirst(),
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: "price",
                    label: this.$t('invoices::items.relationships.price').ucFirst(),
                    formatter: (value, index, item) => this.formatter.format(Math.ceil(item?.quantity * (parseFloat(item?.price) + parseFloat(item?.vat)))),
                    thClass: 'text-center min-width bg-light',
                    tdClass: 'text-end bg-light'
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
            ];
        },
    },

    data: function () {
        return {
            type: 'create',
            view: 'selector',
            formatter: new Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: 'HUF',
                minimumFractionDigits: 0,
                currencyDisplay: 'narrowSymbol',
            })
        };
    },

    methods: {
        close: function () {
            this.view = 'selector';
        },

        init: function () {
            this.$store.dispatch(`${this.store}/init`, JSON.parse(JSON.stringify(this.modelValue || [])));
        },

        add: function () {
            this.$store.dispatch(`${this.store}/add`);
        },

        update: function (item) {
            this.$store.dispatch(`${this.store}/update`, item);
        },

        remove: function (index) {
            this.$store.dispatch(`${this.store}/remove`, index);
        },

        edit: function (index) {
            this.type = 'edit';
            this.view = 'editor';
            this.$store.dispatch(`${this.store}/edit`, index);
        },

        create: function () {
            this.$store.dispatch(`${this.store}/create`);
            this.type = 'create';
            this.view = 'editor';
        },

        clear: function () {
            this.$store.dispatch(`${this.store}/clearAll`);
        },
    },

    watch: {
        model: function (value) {
            this.$emit('update:modelValue', value);
        },

        summary: function (value) {
            this.$emit('update:amount', value);
        },
    },

    mounted() {
        this.init();
    },

    unmounted() {
        this.clear();
    }
}
</script>

<template>
    <div class="">
        <div class="row">
            <div class="col-12">
                <h3 class="text-center text-primary mb-4 border-bottom pb-2">{{ $tc('invoices::items.item', 2).ucFirst() }}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                <div class="py-4 d-flex align-items-center justify-content-center w-100 h-100 bg-soft-primary bg-gradient rounded">
                    <i class="font-size-34 text-primary fas fa-cash-register"></i>
                </div>
            </div>
            <div class="col-lg-10">
                <transition-toggle>
                    <simple-table v-if="view === 'selector'" :items="model" :fields="fields">
                        <template #actions="{ index }">
                            <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="edit(index)">
                                <i class="fas fa-edit"></i>
                            </span>
                            <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="remove(index)">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                        </template>
                        <template #foot_name>
                            <b-button size="sm" variant="success" @click="create" :title="$t('base.create').ucFirst()">
                                <i class="fas fa-plus-square"></i>
                            </b-button>
                        </template>
                        <template #foot_price>
                            {{ formattedSummary }}
                        </template>
                    </simple-table>
                    <billable-general-form
                        v-else
                        :type="type"
                        @add="add"
                        @update="update"
                        @close="close"
                    ></billable-general-form>
                </transition-toggle>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>