<script>
import {mapGetters} from "vuex";

export default {
    name: "billable-implement-part-item",

    props: {
        item: {
            type: Object,
            required: true
        },

        validation: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        summary: function () {
            if (!this.item?.price || !this.item?.vat || !this.item?.quantity) {
                return null;
            }

            return Math.ceil((parseFloat(this.item.price) + parseFloat(this.item.vat)) * this.item.quantity);
        },

        bgColor: function () {
            if (! this.validation) {
                return {};
            }

            return {
                'bg-soft-success': this.item?.valid_item === true && this.item?.quantity_ok === true,
                'bg-soft-warning': this.item?.valid_item === true && this.item?.quantity_ok !== true
            }
        },

        quantityTextColor: function () {
            if (! this.validation) {
                return {};
            }

            return {
                'text-success': this.item?.quantity_ok === true,
                'text-danger': this.item?.quantity_ok === false
            };
        },
    },


}
</script>

<template>
    <div class="item-card border rounded d-inline-block bg-gradient bg-soft-primary overflow-hidden cursor-pointer"
        :class="bgColor"
    >
        <div class="p-1">
            <div class="mb-3 text-center font-weight-bold p-2 bg-white rounded border ">
                <span class="font-size-24 text-primary"
                      :class="quantityTextColor"
                >{{ item?.quantity }}
                    <template v-if="validation">
                        <span v-if="item?.quantity_ok === true" class="d-block">
                            <i class="fas fa-check-double"></i>
                        </span>
                        <span v-if="item?.quantity_ok === false" class="d-block">
                            <i class="fas fa-times"></i>
                        </span>
                    </template>
                </span>
            </div>
            <div class="text-center mb-3 px-2">
                <span class="font-size-12">{{ item?.billable?.name }}</span>
            </div>
        </div>
        <div class="bg-primary text-white opacity-75 font-weight-bold font-size-10">
            <div class="text-center">
                <span>{{ summary?.printCurrency(item?.currency, locale) }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.item-card {
    min-width: 100px;
    transition: 0.3s;
    &:hover {
        transform: scale(1.05);
        transition: 0.3s;
    }
}
</style>